// Breakpoint variables.
$screen-sm:                  768px !default;
$screen-md:                  992px !default;
$screen-lg:                  1200px !default;
$screen-sm-min:              $screen-sm !default;
$screen-xs-max:              ($screen-sm-min - 1) !default;

// Breakpoint mixin.
// Usage: @include breakpoint(sm) { ... }
// -----------------------------------------------------------------------------
@mixin breakpoint($class) {
  @if $class == xs {
    @media (max-width: $screen-xs-max) { @content; }
  }
  @else if $class == sm {
    @media (min-width: $screen-sm) { @content; }
  }
  @else if $class == md {
    @media (min-width: $screen-md) { @content; }
  }
  @else if $class == lg {
    @media (min-width: $screen-lg) { @content; }
  }
  @else {
    @warn "Breakpoint mixin supports: xs, sm, md, lg";
  }
}

// Font Smoothing.
// --------------------------------------------------
%font-smoothing-on {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
%font-smoothing-off {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

@import "../../scss/base";

// Defaults.
.snp-section-small { min-height: 20vmin; }
.snp-section-medium { min-height: 30vmin; }
.snp-section-large { min-height: 50vmin; }
.snp-color-overlay { background-color: #000; }
.snp-section-image { flex-basis: 40%; }
.snp-section-content { flex-basis: 60%; }
.snp-section-full-width-background.has-bg-img .section-content {
  @extend %font-smoothing-on;
  color: #fff;
}

.paragraphs-item-snp-section {

  > .container-large,
  > .container-small {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
  }

  > .container-large { max-width: 1470px; }
  > .container-small { max-width: 960px; }

  &.has-bg-img {
    .snp-section-image {
      position: relative;
      min-height: 250px;
    }
  }

  &.snp-section-full-width-background {
    position: relative;

    &.snp-section-small,
    &.snp-section-medium,
    &.snp-section-large {
      @include breakpoint(sm) {
        display: flex;
        align-items: center;
      }
    }

    .section-content {
      position: relative;
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
  }

  &.snp-section-image-left,
  &.snp-section-image-right {

    @include breakpoint(sm) {
      display: flex;
    }

    .snp-section-image,
    .snp-section-content {
      flex-grow: 1;
      flex-shrink: 1;
    }

    .snp-section-image {
      position: relative;
    }

    .snp-section-content {
      align-self: center;
      padding-top: 2rem;
      padding-bottom: 2rem;

      @include breakpoint(sm) {
        padding: 3rem;
      }
    }
  }

  &.snp-section-image-right {
    .snp-section-image {
      order: 2;
    }
  }

  .section-title {
    margin-top: 0;
  }

  .video-button {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;

    &:hover,
    &:focus {
      text-decoration: none;
      background-color: rgba(0,0,0,0.6);
    }
  }

  .video-button-centered {
    text-align: center;
    margin-top: 2rem;

    .fa-play {
      margin-right: 0.5rem;
    }
  }
}

.modal .media-youtube-player {
    width: 100%;
  }
